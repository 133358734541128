import React from 'react'
import Layout from '../components/layout'

export default function Contactpage() {
  return (
    <Layout >
      <div>
        <p className='my-4'>
          Soccer24 is the leading football website in Zimbabwe and we would love to hear from you.
          Whether you want to enquire about Zimbabwean football , advertising opportunities or anything else please do get in touch via email.
        </p>
        <p>
            Email: hello@soccer24.co.zw
        </p>
      </div>
    </Layout>
  )
}
